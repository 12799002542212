.container {
  background-color: #ffffff;
  border-radius: 3px 5px 5px 3px;
  box-shadow: 0 11px 14px 0 #e5eced;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  margin-top: 10px;
}
.containerAddress {
  background-color: #ffffff;
  border-radius: 3px 5px 5px 3px;
  box-shadow: 0 11px 14px 0 #e5eced;
  width: 100%;
  min-height: 82px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  margin-top: 10px;
}
.icon {
  margin-left: 13px;
}

.label {
  color: #273d52;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  opacity: 0.9;
  text-align: left;
  margin-right: 0px;
  margin-left: 22px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.price {
  color: #ffb169;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  margin-right: 43px;
  min-width: 66px;
  width: 66px;
}

.address {
  color: #273d52;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  opacity: 0.9000000357627869;
  text-align: left;
  margin-top: 21px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 24px;
}
