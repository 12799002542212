.container {
  background-color: rgba(142, 142, 147, 0.1);
  border-radius: 10px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 0px;
  margin-bottom: 23px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
}

.input {
  background-color: rgba(142, 142, 147, 0);
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
  border: 0px;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.input:focus {
  outline: none;
}
