@import '../../constants/Colors.css';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 36px;
}

.title {
  height: 32;
  width: 100%;
  margin-top: 14px;
  color: var(--color-primary);
  font-size: 21px;
  font-weight: 600;
  letter-spacing: -0.51px;
  line-height: 32px;
  text-align: center;
}

.dashed {
  border-bottom-style: dashed;
  width: 100%;
  height: 0.1px;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #dbe1e7;
  padding-left: 40px;
  padding-right: 40px;
}
