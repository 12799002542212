.container {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
}

.orderProductTitle {
  color: #273d52;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  opacity: 0.7;
  text-align: left;
  width: 100%;
  margin-top: 24px;
}

.orderAddress {
  color: #273d52;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  opacity: 0.5;
  text-align: left;
}
