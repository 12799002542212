.homeInfoContainer {
  background-color: #f3f3f4;
  margin-top: 0px;
}

.homeInfoWrapperContent {
  padding-top: 12px;
  padding-bottom: 25px;
  margin-left: 20px;
  margin-right: 20px;
}

.note {
  font-size: 11px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: -10px;
}
