@import '../../../constants/Colors.css';

.container {
  background-color: #f3f3f3;
  box-shadow: inset 0 1px 9px 1px rgba(0, 0, 0, 0.14);
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: scroll;
}

.inactiveItem {
  background-color: #ffffff;
  border-radius: 19px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  color: #4c4f67;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  flex: 1;
}

.activeItem {
  background-color: var(--color-primary);
  border-radius: 19px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  flex: 1;
}
