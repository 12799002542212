.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.scrollContainer {
  height: 100%;
  width: 100%;
  overflow: scroll;
}
