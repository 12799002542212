.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  z-index: 10;
  overflow-x: scroll;
}
