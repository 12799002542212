@import '../../../constants/Colors.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 85px;
  max-height: 140px;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
}

.labelsContainer {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
}

.subtitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  opacity: 0.7;
  text-align: left;
}
.priceInfo {
  font-size: 10px;
  padding-top: 5px;
  color: white;
}

.button {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 #5774be;
  width: 195px;
  height: 46px;

  color: #273d52;
  font-size: 15px;
  font-weight: 700;
  line-height: 46px;
  opacity: 0.9000000357627869;
  text-align: center;
  margin-right: 20px;
  vertical-align: middle;
}

.disabled {
  opacity: 0.7;
}

.topButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 20px;
  background-color: #b3003f;
  position: absolute;
  bottom: 80px;
}
