.container {
  height: 100%;
  width: 100%;
  z-index: 15;
}

.loaderContainer {
  position: absolute;
  height: 100px;
  bottom: 25vh;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.splashImageContainer {
  width: 100%;
  height: 100vh;
}

.splashImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
