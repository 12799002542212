.button {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow:  0 2px 6px 0 #000000;
  width: 195px;
  height: 46px;

  color: #273d52;
  font-size: 15px;
  font-weight: 700;
  line-height: 46px;
  opacity: 0.9000000357627869;
  text-align: center;
  margin-right:20px;
  vertical-align: middle;


}
