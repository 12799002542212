@import '../../../constants/Colors.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 55px;
  width: 100%;
}

.cellContainer {
  background-color: #ffffff;
  border-radius: 3px 5px 5px 3px;
  box-shadow: -1px 10px 19px 6px #e5eced;
  width: 100%;
  height: 55px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.line {
  background-color: var(--color-primary);
  border-radius: 5px 0 0 5px;
  box-shadow: 0 2px 11px 0 rgba(203, 207, 211, 0.5);
  width: 3.09px;
  height: 55px;
}

.cancelled {
  background-color: var(--errorColor);
}

.priceContainer {
  background-color: #fee7cc;
  border-radius: 5px;
  opacity: 0.6174252836600594;
  height: 35px;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  width: '60px';
  margin-right: 55px;
  padding-left: 8px;
  padding-right: 8px;
}

.priceLabel {
  color: #ffb169;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.dataContainer {
  margin-left: 17px;
  margin-right: 80px;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  min-height: 55px;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.firstContainer {
  justify-content: flex-start;
  vertical-align: middle;
  display: flex;
  align-items: flex-start;
  text-align: center;
  flex-direction: row;
}

.title {
  color: #273d52;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  opacity: 0.9000000357627869;
  text-align: right;
}

.subtitle {
  color: #273d52;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.6000000238418579;
  text-align: left;
  width: 100%;
}

.expandButton {
  flex: 0;
  right: 33px;
  position: absolute;
  width: 20;
}

.expandContainer {
  width: 100%;
  background-color: #efefef;
  border-radius: 0 0 10px 10px;
  opacity: 0.8344029017857143;
}
