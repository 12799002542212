.container {
  height: 160px;
  width: 100%;
  background-color: #fff;
}

.title {
  color: #55586e;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.subtitle {
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.itemContainer {
  width: 100%;
  background-color: #fff;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 10px;
}
