.container {
  position: absolute;
  left: 36px;
  right: 36px;
  top: 78px;
  bottom: 50px;
  background-color: #eeeeee;
  z-index: 999;
  border-radius: 20px;
  padding: 26px;
  text-align: justify;
}

.content {
  margin-top: 18px;
}
.close {
  position: absolute;
  right: 18px;
  top: 18px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
