@import '../../../constants/Colors.css';

.flex-column {
  display: flex;
  flex-direction: column;
}

.container {
  background-color: #ffffff;
  border: 1px solid var(--color-primary);
  border-radius: 6px;
  width: 90px;
  height: 38px;

  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: space-between;
  vertical-align: middle;
}

.button {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 11px;
  padding-right: 11px;
}

.title {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.minimumQuantity {
  font-size: 10px;
  font-weight: 300;
  color: #212529;
}
