body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  font-style: normal;
  src: url('./assets/fonts/Montserrat/Montserrat-Light.ttf');
}

/* ,local('./assets/fonts/Montserrat/Montserrat-Bold.ttf') */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none !important;
}
