.container {
  position: fixed;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(132, 168, 188, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
