:root {
  --footer-tab-bar-height: 60px;
}
.container {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.scrollContainer {
  overflow-y: scroll;
  position: inherit;
  width: 100%;
  height: calc(100% - var(--footer-tab-bar-height));
}
.headerContainer {
  width: 100%;
  height: 60px;
  top: 0px;
}
.bottomNavigation {
  width: '100%';
  height: var(--footer-tab-bar-height);
  position: 'fixed';
  bottom: 0;
}
