@import '../../../constants/Colors.css';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  background-color: var(--color-primary);
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0px;
  min-height: var(--product-count-height);
  max-height: 140px;
  margin-bottom: 60px;
}
.counterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  height: 100%;
  margin-left: 20px;
}

.title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
}

.subtitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  opacity: 0.7;
  text-align: left;
}
.priceInfo {
  font-size: 10px;
  padding-top: 5px;
  color: white;
}
