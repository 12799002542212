.container {
  background-color: var(--errorColor);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}

.finishButton {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 11px 14px 0 #ec281a;

  padding-left: 90px;
  padding-right: 90px;
  color: #273d52;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  opacity: 0.9000000357627869;
  text-align: left;
  height: 46px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}

.title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 37px;
  width: 303px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
}

.subtitle {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  width: 294px;
  text-align: center;
  margin-bottom: 36px;
}
.cancel {
  margin-top: 16px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  opacity: 0.9000000357627869;
  text-align: left;
}
