.container {
  margin: 10px 24px 32px;
  border-bottom: 1px solid #e2e2e2;
}

.title {
  color: #3e415b;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}

.price {
  color: #9b9b9b;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  width: 82px;
  text-align: left;
}

.amount {
  color: #92cbe9;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  width: 183px;
  text-align: center;
}

.dataContainer {
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
}

.packItems {
  display: flex;
  padding: 8px 0px;
  margin: 13px 0;
  flex-direction: column;
}

.packItemLine {
  padding: 4px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.packIteTitle {
  color: #3e415b;
  font-size: 13px;
}

.packItemAmount {
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
