@import '../../../constants/Colors.css';

.login-button {
  background-color: var(--color-primary);
  border-radius: 100px;
  margin-right: 13.3%;
  margin-left: 13.3%;
  height: 49px;
  margin-top: 40px;
  margin-bottom: 0px;
  align-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 6px 0 #5774be;
}

.register-button {
  background-color: #ffffff;
  border: 1px solid var(--color-primary);
  border-radius: 100px;
  border-radius: 100px;
  margin-right: 13.3%;
  margin-left: 13.3%;
  height: 48px;
  margin-top: 38px;
  margin-bottom: 30px;
  align-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 6px 0 var(--color-primary);
}

.register-fill-button {
  background-color: var(--color-primary);
  border-radius: 100px;
  margin-right: 13.3%;
  margin-left: 13.3%;
  height: 49px;
  margin-top: 28px;
  margin-bottom: 0px;
  align-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 6px 0 #5774be;
}
.login-label {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.3px;
  line-height: 46px;
  text-align: center;
  vertical-align: middle;
}

.register-fill-label {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.3px;
  line-height: 46px;
  text-align: center;
  vertical-align: middle;
}

.register-label {
  color: var(--color-primary);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.3px;
  line-height: 46px;
  text-align: center;
  vertical-align: middle;
}

.disabled {
  opacity: 0.6;
}
