:root {
  --nav-bar-height: 56px;
  --left-container-width: 60px;
}
.container {
  height: 60px;
  background-color: #f2f6fb;
  border-radius: 0 0 2px 2px;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.containerError {
  border-color: red;
  border-width: 2px;
  height: 60px;
  background-color: #f2f6fb;
  border-radius: 0 0 2px 2px;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
}

.leftContainer {
  height: var(--left-container-width);
  width: var(--left-container-width);
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputContainer {
  width: 100%;
  background-color: #f2f6fb;
  border: 0;
  border-color: red;
  border-width: 2px;
}

.input {
  height: 100%;
  width: 100%;
  background-color: #f2f6fb;
  border: 0;
}

.select {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  margin-left: -12px;
}

.showPassword {
  margin: 0 15px;
}
