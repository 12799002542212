.container {
  position: relative;
  margin-left: 19px;
  margin-right: 19px;
  margin-top: 19px;
  margin-bottom: 19px;
}

.data {
  float: left;
  position: absolute;
  left: 32px;
  bottom: 10%;
  z-index: 99;
  color: #ffffff;
  font-weight: bold;
}

.bannerImage {
  position: relative;
  max-height: 30%;
  height: auto;
}

@media (min-width: 850px) {
  .bannerImage {
    height: 70vh;
  }
}

.orderPackButton {
  opacity: 1;
  position: absolute;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 11px 14px 0 rgba(117, 131, 188, 0.74);
  min-width: 118.43px;
  height: 41.9px;
  color: #273d52;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  opacity: 0.9000000357627869;
  width: 74.15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: 0;
}
