@import '../../../constants/Colors.css';

.button {
  background-color: var(--color-primary);
  border: 1px solid #e5eced;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 #e5eced;
  width: 100%;
  height: 44px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled {
  opacity: 0.7;
}
.cantEdit {
  font-size: 10px;
  margin-top: 5px;
  color: gray;
  text-align: center;
}
