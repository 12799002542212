@import '../../../constants/Colors.css';

.container {
  background-color: #ffffff;
  border: 1px solid #e5eced;
  border-radius: 5px;
  box-shadow: 0 11px 14px 0 #e5eced;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.dateContainer {
  background-color: var(--color-primary);
  border-radius: 5px 0 0 5px;
  box-shadow: 0 2px 11px 0 rgba(203, 207, 211, 0.5);
  width: 15%;
  min-width: 58px;
  max-width: 90px;
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dateTitle {
  color: #ffffff;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}

.dateSubtitle {
  color: #ffffff;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
}

.activityContainer {
  margin-left: 17px;
}
.activityTitle {
  color: #273d52;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  opacity: 0.9000000357627869;
  text-align: left;
}

.activitySubtitle {
  color: #273d52;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
  text-align: left;
}

.nextRoute {
  padding: 10px;
}
