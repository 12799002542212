@import '../../constants/Colors.css';

.container {
  align-content: center;
  vertical-align: center;
  align-items: center;
  text-align: center;
}

*:focus {
  outline: none;
}

.logo {
  margin-top: 64px;
  height: 142px;
  margin-bottom: 39px;
}

.logo-small {
  width: 30px;
  margin-top: 16px;
  height: 52px;
  margin-bottom: 19px;
}

.title {
  color: var(--color-primary);
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.55px;
  line-height: 32px;
  text-align: center;
  margin-left: 26px;
  margin-right: 26px;
  margin-bottom: 13px;
}

.subtitle {
  color: #4b5b63;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-left: 42px;
  margin-right: 42px;
  margin-bottom: 26px;
}

.formInfo {
  font-size: 14px;
  margin-top: 10px;
}

.bottomContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.version {
  color: gray;
  padding-bottom: 10px;
  padding-right: 10px;
}
