.container {
  background-color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.pack {
  border: 1px dashed #979797;
  border-radius: 7px;
  width: calc(100% - 40px) !important;
  margin: 20px;
}

.containerActive {
  background-color: #ebf5fb;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.iconContainer {
  background-color: #fff;
  height: 100%;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 18px;
}

.dataContainer {
  margin: 0px 20px;
  width: calc(100%);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.bottomContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.horizontalLine {
  background-color: #e2e2e2;
  left: 10px;
  right: 10px;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.title {
  margin-top: 22px;
  color: #3e415b;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin-right: 16px;
}

.price {
  color: #9b9b9b;
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.img {
  width: 53px;
}

.quantity {
  color: #9b9b9b;
  margin-top: 11px;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.packItems {
  width: 100%;
  padding: 10px 0px;
}

.packItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px;
}

.packIteTitle {
  font-size: 14px;
  font-weight: 150;
  color: #3e415b;
}

.packItemAmount {
  font-size: 13px;
  font-weight: 150;
  color: #888888;
}
