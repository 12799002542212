.commentsContainer {
  border-top: 1px solid #ddd;
  background: #ebf5fb;
  padding: 10px 24px;
}

.textarea {
  border: none !important;
  border-radius: 0 !important;
  height: 100px !important;
  resize: none;
}